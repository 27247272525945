import React, { memo } from 'react';
import Card from '../card';

import './price-card.css';

export interface PriceCardProps {
  color: string;
  title: string;
  price: number;
  pricePerMonth?: number;
  priceMax?: number;
  points?: number;
  icon?: React.ReactNode;
  extras?: { [key: string]: string };
  elevation?: 0 | 1 | 2 | 3 | 4;
  aaa?: boolean;
  aaaDiscount: number;
  aaaDiscountPerMonth?: number;
  style?: React.CSSProperties;
  onClick?: VoidFunction;
  priceRanges?: boolean;
}

const PriceCard: React.FC<PriceCardProps> = ({
  style,
  color,
  title,
  price,
  priceMax,
  pricePerMonth,
  points,
  icon,
  extras,
  elevation = 2,
  aaa = false,
  aaaDiscount,
  aaaDiscountPerMonth,
  onClick,
  priceRanges
}) => {
  return (
    <Card
      style={{
        width: 260,
        height: 390,
        minHeight: 390,
        cursor: 'pointer',
        ...style
      }}
      topperColor={color}
      topperSize="small"
      elevation={elevation}
      onClick={onClick}
      hoverIncreaseElevation={1}
    >
      <div
        style={{
          display: 'flex',
          flexDirection: 'column',
          alignItems: 'center',
          height: '100%'
        }}
      >
        <h1
          style={{
            margin: 0,
            textAlign: 'center',
            fontWeight: 700,
            fontSize: '30px',
            lineHeight: '18px',
            letterSpacing: 0,
            marginBottom: 'var(--spacing)'
          }}
        >
          {title}
        </h1>
        {icon && icon}
        <div style={{ display: 'none' }}>
          {points !== 0 ? (
            <div
              style={{
                color,
                fontWeight: 700,
                fontSize: '12px',
                lineHeight: '20px',
                letterSpacing: 0
              }}
            >
              {aaa ? 'AAA MEMBERS EARN 2X POINTS' : 'LOYALTY POINTS'}
            </div>
          ) : null}
          {points !== 0 ? (
            <div
              style={{
                textAlign: 'center',
                fontWeight: 300,
                fontSize: '16px',
                lineHeight: '20px',
                letterSpacing: 0
              }}
            >
              {aaa ? `${points} + ${points} = ${2 * points}` : `${points}`}
            </div>
          ) : null}
        </div>
        {extras && (
          <div style={{ marginTop: 'var(--spacing)' }}>
            {Object.keys(extras).map((key, idx) => (
              <div
                key={key}
                style={{
                  display: 'flex',
                  alignItems: 'flex-start',
                  flexDirection: 'column',
                  ...(idx > 0 ? { marginTop: 'var(--spacing)' } : {})
                }}
              >
                <div
                  style={{
                    fontWeight: 300,
                    fontSize: '14px',
                    lineHeight: '18px',
                    letterSpacing: 0
                  }}
                >
                  <div
                    style={{
                      fontWeight: 500,
                      fontSize: '14px',
                      lineHeight: '18px',
                      letterSpacing: 0,
                      display: 'inline-flex',
                      marginRight: 'var(--spacing-sm)'
                    }}
                  >
                    {key}
                  </div>
                  {extras[key]}
                </div>
              </div>
            ))}
          </div>
        )}
        <div
          style={{
            display: 'flex',
            alignItems: 'center',
            justifyContent: 'flex-end',
            flex: 1,
            flexDirection: 'column',
            width: '100%'
          }}
        >
          <div
            style={{
              display: 'flex',
              alignItems: 'center',
              flexDirection: 'column',
              width: '100%'
            }}
          >
            <div
              style={{
                color,
                fontWeight: 500,
                fontSize: '12px',
                lineHeight: '10px',
                letterSpacing: 0,
                marginBottom: 'var(--spacing-sm)'
              }}
            >
              {aaa ? 'AAA MEMBER PRICE' : null}
            </div>
            {priceRanges ? (
              <PriceInfoRange
                aaa={aaa}
                aaaDiscount={aaaDiscount}
                price={price}
                priceMax={priceMax}
                color={color}
                pricePer="Per Month"
              />
            ) : (
              <div className="PriceContainer">
                <PriceInfo
                  aaa={aaa}
                  color={color}
                  aaaDiscount={aaaDiscount}
                  price={price}
                  pricePer="Per Month"
                  title={false}
                />&nbsp;
                {pricePerMonth !== 0 ? (
                  <PriceInfo
                    aaa={aaa}
                    color={color}
                    aaaDiscount={aaaDiscountPerMonth}
                    price={pricePerMonth}
                    pricePer="Per Month"
                    title={true}
                  />
                ) : null}
              </div>
            )}
          </div>
        </div>
      </div>
    </Card>
  );
};

export default memo(PriceCard);

interface PriceInfoProps {
  aaa: boolean;
  aaaDiscount: number;
  price: number;
  pricePer: string;
  style?: React.CSSProperties;
  color?: string;
  title?: boolean;
}

export const PriceInfo: React.FC<PriceInfoProps> = ({
  aaa,
  aaaDiscount,
  price,
  pricePer,
  color,
  title
}) => (
  <div className="PriceInfo">
    {pricePer === 'Per Wash' ? (
      <div className="PriceInfo__bubble" style={{ backgroundColor: color }}>
        <div className="PriceInfo__amount" style={{ justifyContent: 'center' }}>
          ${aaa ? price - aaaDiscount : price}
        </div>
        <div className="PriceInfo__per">{pricePer || <>&nbsp;</>}</div>
      </div>
    ) : (
      <>
        {title ? 
        <div className="PriceInfo__per" style={{ color: "var(--pallet-violet)", marginTop: "-20px", marginBottom: 5 }}>
          AAA Members
        </div> : null }
        <div className="PriceInfo__amount" style={{ color: "black" }}>
          ${aaa ? price - aaaDiscount : price}
          <div className="PriceInfo__amount-99">99</div>
        </div>
        <div className="PriceInfo__per" style={{ color: "black" }}>
          {pricePer || <>&nbsp;</>}
        </div>
      </>
    )}
  </div>
);

interface PriceInfoRangeProps {
  aaa: boolean;
  aaaDiscount: number;
  price: number;
  priceMax: number;
  pricePer: string;
  style?: React.CSSProperties;
  color?: string;
}

export const PriceInfoRange: React.FC<PriceInfoRangeProps> = ({
  aaa,
  aaaDiscount,
  price,
  priceMax,
  pricePer,
  color
}) => (
  <div className="PriceInfo">
    <div
      className="PriceInfo__bubble"
      style={{ backgroundColor: color, marginRight: 0 }}
    >
      <div className="PriceInfo__amount">
        ${aaa ? price - 1 : price}
        <div className="PriceInfo__amount-99">99</div> - $
        {aaa ? priceMax - aaaDiscount : priceMax}
        <div className="PriceInfo__amount-99">99</div>
      </div>
      <div className="PriceInfo__per">{pricePer || <>&nbsp;</>}</div>
    </div>
  </div>
);
