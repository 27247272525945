import React, { useState } from 'react';
import BigText from '../../components/big-text';
import DotNav from '../../components/dot-nav';
import PriceCard from '../../components/price-card/wash-club';
import HorizontalScroll from '../../components/horizontal-scroll';
import Button from '../../components/button';
import SectionSlideOver from '../../components/section-slide-over';
import TopWashClubIcon from '../../components/icons/top-wash-club';

import { NavItem } from '../../utils/types';
import { useMedia } from '../../utils/hooks';

import './pricing.css';
import JoinAAA from '../joinaaa';

export interface PricingProps {
  number?: string;
  style?: React.CSSProperties;
  navItems?: Array<NavItem>;
  onNavItemSelected?: (index: number) => void;
  sale?: boolean;
  title?: boolean;
}

const PricingWashClub: React.FC<PricingProps> = ({
  number,
  style,
  navItems,
  onNavItemSelected,
  sale = false,
  title = true
}) => {
  const [aaa, setAAA] = useState<boolean>(false);
  const [basicOpen, setBasicOpen] = useState<boolean>(false);
  const [standardOpen, setStandardOpen] = useState<boolean>(false);
  const [topWashOpen, setTopWashOpen] = useState<boolean>(false);
  const [topWashClubOpen, setTopWashClubOpen] = useState<boolean>(false);
  const scrollOptions = useMedia(
    [
      '(min-width:1650px)',
      '(min-width:1300px)',
      '(min-width:1095px)',
      '(min-width:760px)',
      '(min-width:0px)'
    ],
    [
      { slidesToShow: 5, variableWidth: false },
      { slidesToShow: 4 },
      { slidesToShow: 3 },
      { slidesToShow: 2 },
      { slidesToShow: 1 }
    ],
    {
      slidesToShow: 5,
      variableWidth: false
    }
  );
  const textOptions = useMedia(
    ['(max-width: 770px)', '(max-width: 414px)'],
    [
      {
        number: {
          left: '-20px'
        }
      },
      {
        number: {
          left: '-20px'
        }
      }
    ],
    {
      number: {
        left: '-100px'
      }
    }
  );
  const toggleAAA = () => setAAA(!aaa);

  function handleBasicClick() {
    setBasicOpen(!basicOpen);
  }

  function handleStandardClick() {
    setStandardOpen(!standardOpen);
  }

  function handleTopWashClick() {
    setTopWashOpen(!topWashOpen);
  }

  function handleTopWashClubClick() {
    setTopWashClubOpen(!topWashClubOpen);
  }

  return (
    <div id="Pricing" style={style}>
      <div className="Pricing-background-text">
        {number && (
          <BigText
            value={number}
            thin={true}
            style={{ position: 'absolute', top: '20px', ...textOptions.number }}
          />
        )}
      </div>
      <div className="Pricing-content">
        {title ? (
          <div className="Pricing-title">
            <div className="Pricing-title-content">
              <div className="Pricing-title-content-top">CHECK OUT THE</div>
              <div className="Pricing-title-content-main">
                Best Washes in Town
              </div>
              {number && (
                <DotNav
                  selected={parseInt(number, 10) - 1}
                  items={navItems}
                  dark={true}
                  onChange={onNavItemSelected}
                  style={{ marginTop: 'var(--spacing)' }}
                />
              )}
            </div>
          </div>
        ) : null}
        <div className="Pricing-details">
          <HorizontalScroll
            key={scrollOptions.slidesToShow}
            dark={true}
            infinite={true}
            dots={false}
            variableWidth={true}
            {...scrollOptions}
          >
            <div className="Pricing-item" style={{ paddingLeft: '1px' }}>
              <PriceCard
                style={{
                  marginRight: 'var(--spacing)',
                  marginLeft:
                    scrollOptions.slidesToShow === 4 ? 'var(--spacing-sm)' : 0
                }}
                title="AquaFIN Armor"
                color="var(--pallet-violet)"
                points={sale ? 0 : 15}
                price={sale ? 17 : 44}
                pricePerMonth={sale ? 34 : 39}
                aaaDiscount={3}
                aaaDiscountPerMonth={5}
                aaa={aaa}
                extras={{
                  'Includes:':
                    'Graphene, Ceramic Protectant, 2-Stage Wheel Cleaner, Triple Foam, Tire Shine, Underbody, High Pressure',
                  'Plus:':
                    'Free vacuums, mat cleaners, glass cleaner and towels at all locations'
                }}
              />
            </div>
            <div className="Pricing-item" style={{ paddingLeft: '1px' }}>
              <PriceCard
                style={{
                  marginRight: 'var(--spacing)',
                  marginLeft:
                    scrollOptions.slidesToShow === 4 ? 'var(--spacing-sm)' : 0
                }}
                title="Protect"
                color="var(--pallet-orange)"
                points={sale ? 0 : 15}
                price={sale ? 5 : 39}
                pricePerMonth={sale ? 0 : 34}
                aaaDiscount={2}
                aaaDiscountPerMonth={5}
                aaa={aaa}
                extras={{
                  'Includes:':
                    'Ceramic Protectant, Carnauba Wax, Sea Foam, 2-Stage Wheel Cleaner, Triple Foam, Tire Shine, High Pressure',
                  'Plus:':
                    'Free vacuums, mat cleaners, glass cleaner and towels at all locations'
                }}
              />
            </div>
            <div className="Pricing-item">
              <PriceCard
                style={{
                  marginRight: 'var(--spacing-sm)'
                }}
                title="Essential"
                color="var(--pallet-red)"
                points={sale ? 0 : 20}
                price={sale ? 10 : 29}
                pricePerMonth={sale ? 20 : 26}
                aaaDiscount={1}
                aaaDiscountPerMonth={3}
                aaa={aaa}
                extras={{
                  'Includes:':
                    'Tire & Wheel Cleaner, Triple Foam, Tire Shine, Underbody',
                  'Plus:':
                    'Free vacuums, mat cleaners, glass cleaner and towels at all locations'
                }}
              />
            </div>
            <div className="Pricing-item">
              <PriceCard
                style={{
                  marginRight: 'var(--spacing-sm)'
                }}
                title="Express"
                color="var(--pallet-blue)"
                points={sale ? 0 : 19}
                price={sale ? 0 : 19}
                pricePerMonth={sale ? 30 : 18}
                aaaDiscount={0}
                aaaDiscountPerMonth={1}
                aaa={aaa}
                extras={{
                  'Add on:':
                    'Clean, Shiny, Dry!',
                  'Plus:':
                    'Free vacuums, mat cleaners, glass cleaner and towels at all locations'
                }}
              />
            </div>
            
            {scrollOptions.slidesToShow < 4 && (
              <div className="Pricing-item"></div>
            )}
          </HorizontalScroll>
        </div>

        

        {/* <div className="Pricing-action">
          <Button
            text={aaa ? 'VIEW STANDARD PRICING' : 'VIEW AAA MEMBER PRICING'}
            onClick={toggleAAA}
          />
        </div> */}

        <div className="Pricing-action">
          <Button
            text="JOIN UNLIMITED NOW"
            href="https://finswash.com/join-wash-club/"
          />
        </div>
      </div>

      <SectionSlideOver
        open={basicOpen}
        onClose={handleBasicClick}
        style={{ color: 'var(--pallet-black)', padding: 'var(--spacing-lg)' }}
      >
        Some content about basic wash
      </SectionSlideOver>
      <SectionSlideOver
        open={standardOpen}
        onClose={handleStandardClick}
        style={{ color: 'var(--pallet-black)', padding: 'var(--spacing-lg)' }}
      >
        Some content about standard wash
      </SectionSlideOver>
      <SectionSlideOver
        open={topWashOpen}
        onClose={handleTopWashClick}
        style={{ color: 'var(--pallet-black)', padding: 'var(--spacing-lg)' }}
      >
        Some content about Top Wash wash
      </SectionSlideOver>
      <SectionSlideOver
        open={topWashClubOpen}
        onClose={handleTopWashClubClick}
        style={{ color: 'var(--pallet-black)', padding: 'var(--spacing-lg)' }}
      >
        Some content about Top Wash Club wash
      </SectionSlideOver>
    </div>
  );
};

export default PricingWashClub;
