import React from 'react';
import { NavItem } from '../../utils/types';
import './washplans.css';

import Button from '../../components/button';
import { Link } from 'gatsby';
import TopWashClubIcon from '../../components/icons/top-wash-club';
import { leftArrow } from '../../stories/8-Icons.stories';

export interface WashPlansProps {
  number?: string;
  headerText?: string;
  headerTextMain?: string;
  style?: React.CSSProperties;
  navItems?: Array<NavItem>;
  onNavItemSelected?: (index: number) => void;
}

const WashPlans: React.FC<WashPlansProps> = ({
  number,
  headerText = '',
  headerTextMain = 'Fins Unlimited Washes for Unbeatable Shine',
  style,
  navItems,
  onNavItemSelected
}) => {
  const handleGetStarted = () => {
    // e.preventDefault();
    // e.stopPropagation();
    // TODO handle get started
    history.pushState({}, 'FinsWash', `/#Location`);
    window.location.reload();
  };

  return (
    <div id="WashPlans" style={style}>
      <div className="WashPlans-content">
        <div className="WashPlans-inner-content">
          <div className="WashPlans-title">
            <div className="WashPlans-title__content">
              <h3 className="WashPlans-title__content-top">{headerText}</h3>
              <h1 className="WashPlans-title__content-main">
                {headerTextMain}
              </h1>
            </div>
          </div>
          <div className="WashPlans-details">
            <p>
              Join Fins Wash Club today and enjoy an stress-free, FINtastic wash
              as often as you’d like for one, low monthly price!
            </p>
          </div>
        </div>
      </div>

      <Link to="/join-wash-club/" style={{ display: 'inline-block' }}>
        <Button
          text="JOIN UNLIMITED NOW"
          style={{ marginTop: 32, marginBottom: 16, marginRight: 16 }}
          className="ctaFLocate"
        />
      </Link>

      {/* <TopWashClubIcon secondaryColor="currentColor" /> */}
      {/* <img
        src="/icons/fins-wash-club-logo.png"
        width="25%"
        style={{ marginTop: 32, display: 'block' }}
      /> */}
      <br />
      <br />
      <h2>Benefits of our Unlimited Plans</h2>

      <div style={{ marginBottom: 40 }}>
        <div
          style={{
            maxWidth: 260,
            width: '100%',
            height: 60,
            display: 'inline-flex',
            marginRight: 40
          }}
        >
          <img
            src="/icons/quickicon.svg"
            style={{ float: 'left', marginRight: 20 }}
          />
          Quick, convenient, and contactless
        </div>
        <div
          style={{
            maxWidth: 260,
            width: '100%',
            height: 60,
            display: 'inline-flex',
            marginRight: 40
          }}
        >
          <img
            src="/icons/caricon.svg"
            style={{ float: 'left', marginRight: 20 }}
          />
          Wash at any Fins Car Wash location
        </div>
        <div
          style={{
            maxWidth: 260,
            width: '100%',
            height: 60,
            display: 'inline-flex',
            marginRight: 40
          }}
        >
          <img
            src="/icons/sparkleicon.svg"
            style={{ float: 'left', marginRight: 20 }}
          />
          Hassle-free automatic monthly billing and no contracts
        </div>
      </div>
      <div>
        <div
          style={{
            maxWidth: 260,
            width: '100%',
            height: 60,
            display: 'inline-flex',
            marginRight: 40
          }}
        >
          <img
            src="/icons/washicon.svg"
            style={{ float: 'left', marginRight: 20 }}
          />
          State-of-the-art equipment and low-stress loading system
        </div>
        <div
          style={{
            maxWidth: 260,
            width: '100%',
            height: 60,
            display: 'inline-flex',
            marginRight: 40
          }}
        >
          <img
            src="/icons/interioricon.svg"
            style={{ float: 'left', marginRight: 20 }}
          />
          Free vacuums at all locations
        </div>
        <div
          style={{
            maxWidth: 260,
            width: '100%',
            height: 60,
            display: 'inline-flex',
            marginRight: 40
          }}
        >
          <img
            src="/icons/thumbicon.svg"
            style={{ float: 'left', marginRight: 20 }}
          />
          Flexible – cancel anytime
        </div>
      </div>

      <div style={{ display: 'none', alignItems: 'center' }}>
        <a href="https://apps.apple.com/us/app/fins-car-wash/id1468541734">
          <img
            className="apple"
            style={{ maxWidth: 216, maxHeight: 56, margin: 0 }}
            alt="Get it on the Apple Store"
            src="https://aaacdndev.blob.core.windows.net/wordpress/2019/11/appStoreBadge.png"
          />
        </a>
        <a href="https://play.google.com/store/apps/details?id=com.aaa.finsmc&amp;hl=en_US&amp;pcampaignid=pcampaignidMKT-Other-global-all-co-prtnr-py-PartBadge-Mar2515-1">
          <img
            className="android"
            style={{ maxWidth: 216, margin: 0 }}
            alt="Get it on Google Play"
            src="https://play.google.com/intl/en_us/badges/static/images/badges/en_badge_web_generic.png"
          />
        </a>
      </div>
    </div>
  );
};

export default WashPlans;
