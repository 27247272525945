import React from 'react';
import { graphql, useStaticQuery } from 'gatsby';
import Img from 'gatsby-image';
import { NavItem } from '../../utils/types';
import TopWashClubIcon from '../../components/icons/top-wash-club';

import './exclusivebenefits.css';
import Button from '../../components/button';

export interface ExclusiveBenefitsProps {
  number?: string;
  headerText?: string;
  headerTextMain?: string;
  style?: React.CSSProperties;
  navItems?: Array<NavItem>;
  onNavItemSelected?: (index: number) => void;
}

const ExclusiveBenefits: React.FC<ExclusiveBenefitsProps> = ({
  number,
  headerText = '',
  headerTextMain = 'ExclusiveBenefits',
  style,
  navItems,
  onNavItemSelected
}) => {
 
  return (
    <div id="ExclusiveBenefits" style={style}>
      <div className="ExclusiveBenefits-content">
        <div className="ExclusiveBenefits-inner-content">
          <div className="ExclusiveBenefits-title">
            <div className="ExclusiveBenefits-title__content">
              <h2 style={{ marginBottom: 8, fontWeight: 900 }} >Exclusive AAA Benefits</h2>
              <p className="ExclusiveBenefits-title__subtitle">at Fins Car Wash</p>
            </div>
          </div>
          <div className="ExclusiveBenefits-details">
            <strong style={{ marginBottom: 8 }}>
            Did you know AAA Members save up to $5 month on Fins Wash Club?
            </strong>
            That’s up to $60 a year, almost the cost of a Basic AAA Membership! Keep your 
            car clean and claim all the benefits of a AAA membership.
            <br /><br />
            
            <Button
              text="EXPLORE AAA MEMBERSHIP"
              href="https://www.acg.aaa.com/aaa-membership/classic-basic.html"
              style={{ backgroundColor: '#92BE28' }}
            />
          </div>
        </div>
        <div>
          <img src='/img/fin.png' />
        </div>
      </div>
    </div>
  );
};

export default ExclusiveBenefits;
