import React from 'react';
import { useMedia } from '../utils/hooks';
import SEO from '../components/seo';
import WashPlansSection from '../sections/washplans';
import PricingSection from '../sections/pricing/washclub';
import Footer from '../components/footer';

import { Link } from 'gatsby';

import '../styles/promo-page.css';
import FinswashClubLogoIcon from '../components/icons/finswashclublogo';
import ExclusiveBenefits from '../sections/exclusive-benefits';

function noop() {
  return;
}

const FinsWashClubPage: React.FC<any> = () => {
  const iconSize = useMedia(
    ['(min-width: 376px)', '(min-width: 275px)', '(min-width: 0px)'],
    [
      { width: '206', height: '40' },
      { width: '249.18', height: '47.54' },
      { width: '166.12', height: '31.69' }
    ],
    { width: '206', height: '40' }
  );

  return (
    <div id="PromoTemplate" className="Promo-container WashPlans">
      {/* <PromotionUpdate
        message="The purchase of new Fins Wash Club memberships and Gift Cards 
        are temporarily suspended to reduce direct person-to-person contact. 
        The safety of our customers and team members is a top priority at FINS. 
        We continue to closely monitor the situation with regard to COVID-19 
        and are altering our business practices where necessary.

        In an effort to minimize person-to-person contact between customers and team members, 
        we have temporarily suspended the purchase new Fins Wash Club memberships. 
        We apologize for any inconvenience and thank you for understanding."
      /> */}
      <SEO title="Fins Car Wash" />
      <svg
        className="PromoTemplate__background-img"
        viewBox="0 0 10 10"
        preserveAspectRatio="none"
      >
        <defs>
          <linearGradient id="gradient">
            <stop offset="1%" stopColor="#649A30" />
            <stop offset="80%" stopColor="#FDF1A1" />
          </linearGradient>
        </defs>
        <polygon fill="url(#gradient)" points="0 0 10 0 0 10" />
      </svg>
      <div className="Promo-container__header">
        {/* <FinsWashIcon color="#16478E" {...iconSize} /> */}
        <FinswashClubLogoIcon />
      </div>
      <WashPlansSection />

      <PricingSection />
      {/* <FAQSection /> */}
      <ExclusiveBenefits />
     
      <Footer items={[]} onNavItemSelected={noop} newsletter={false} />
    </div>
  );
};

export default FinsWashClubPage;
